/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-08 12:05:42 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-03-02 18:30:50
 */

//  考试记录详情
<template>
  <div class="Paper">
      <exam-Record-details></exam-Record-details>
  </div>
</template>

<script>
import examRecordDetails from "@/components/examRecordDetails.vue";
export default {
  data() {
    return {};
  },
  components: {
    examRecordDetails
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
  .Paper{
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  }
</style>