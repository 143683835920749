var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"testPaper",style:({ marginTop: _vm.isWxApplets ? '46px' : '' })},[(_vm.isWxApplets)?[_c('van-nav-bar',{attrs:{"title":"答题详情","fixed":"","left-arrow":""},on:{"click-left":_vm.onClickLeft}})]:_vm._e(),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.list.Name))])]),_c('div',{staticClass:"list"},_vm._l((_vm.list.PaperItems),function(li,i){return _c('div',{key:i,staticClass:"li"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(i + 1)+"、"+_vm._s(li.Title))]),_vm._l((li.PaperBankList),function(lb,b){return _c('div',{key:b,staticClass:"paperBank"},[_c('div',{staticClass:"questionTitle"},[_c('p',{staticClass:"tmtype"},[_c('span',{class:lb.QuestionCategory == 0
                    ? 'bgc_3A78F9'
                    : lb.QuestionCategory == 1
                    ? 'bgc_FBB03B'
                    : lb.QuestionCategory == 2
                    ? 'bgc_00AE62'
                    : lb.QuestionCategory == 3
                    ? 'bgc_B938B5'
                    : lb.QuestionCategory == 4
                    ? 'bgc_9682EC'
                    : 'bgc_37E6CF'},[_vm._v(" "+_vm._s(lb.QuestionCategory == 0 ? "单选" : lb.QuestionCategory == 1 ? "多选" : lb.QuestionCategory == 2 ? "判断" : lb.QuestionCategory == 3 ? "填空" : lb.QuestionCategory == 4 ? "简答" : "论述")+" ")]),(
                  lb.QuestionCategory == 0 ||
                  lb.QuestionCategory == 1 ||
                  lb.QuestionCategory == 2
                )?_c('span',{class:lb.StudentAnswerStr != lb.AnswerStr ? 'details_sign' : ''},[_vm._v("题目"+_vm._s(b + 1)+"："+_vm._s(lb.QuestionTitle))]):_c('span',[_vm._v("题目"+_vm._s(b + 1)+"："+_vm._s(lb.QuestionTitle))])])]),(lb.QuestionCategory === 0)?_c('el-radio-group',{attrs:{"disabled":""},model:{value:(lb.StudentAnswerStr),callback:function ($$v) {_vm.$set(lb, "StudentAnswerStr", $$v)},expression:"lb.StudentAnswerStr"}},[_vm._l((lb.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-radio',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(lb.parsingShow == false)?_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.parsClick(lb)}}},[_vm._v("查看答案及解析")]):_vm._e(),(lb.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(lb.AnswerStr == null ? "无" : lb.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(lb.Dial == null ? "无" : lb.Dial))])]):_vm._e()],2):(lb.QuestionCategory === 1)?_c('el-checkbox-group',{attrs:{"disabled":""},model:{value:(lb.StudentAnswerStr),callback:function ($$v) {_vm.$set(lb, "StudentAnswerStr", $$v)},expression:"lb.StudentAnswerStr"}},[_vm._l((lb.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-checkbox',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(lb.parsingShow == false)?_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.parsClick(lb)}}},[_vm._v("查看答案及解析")]):_vm._e(),(lb.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(lb.AnswerStr == null ? "无" : lb.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(lb.Dial == null ? "无" : lb.Dial))])]):_vm._e()],2):(lb.QuestionCategory === 2)?_c('el-radio-group',{attrs:{"disabled":""},model:{value:(lb.StudentAnswerStr),callback:function ($$v) {_vm.$set(lb, "StudentAnswerStr", $$v)},expression:"lb.StudentAnswerStr"}},[_vm._l((lb.BankItem),function(lbb,p){return _c('p',{key:p},[_c('el-radio',{attrs:{"label":lbb.ItemNum}},[_vm._v(_vm._s(lbb.ItemNum)+"、"+_vm._s(lbb.ItemTitle))])],1)}),(lb.parsingShow == false)?_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.parsClick(lb)}}},[_vm._v("查看答案及解析")]):_vm._e(),(lb.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(lb.AnswerStr == null ? "无" : lb.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(lb.Dial == null ? "无" : lb.Dial))])]):_vm._e()],2):(lb.QuestionCategory === 3)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入填空,多个填空以中文；隔开","disabled":""},model:{value:(lb.StudentAnswerStr),callback:function ($$v) {_vm.$set(lb, "StudentAnswerStr", $$v)},expression:"lb.StudentAnswerStr"}}),(lb.parsingShow == false)?_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.parsClick(lb)}}},[_vm._v("查看答案及解析")]):_vm._e(),(lb.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(lb.AnswerStr == null ? "无" : lb.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(lb.Dial == null ? "无" : lb.Dial))])]):_vm._e()],1):(lb.QuestionCategory === 4)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入简答","disabled":""},model:{value:(lb.StudentAnswerStr),callback:function ($$v) {_vm.$set(lb, "StudentAnswerStr", $$v)},expression:"lb.StudentAnswerStr"}}),(lb.parsingShow == false)?_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.parsClick(lb)}}},[_vm._v("查看答案及解析")]):_vm._e(),(lb.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(lb.AnswerStr == null ? "无" : lb.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(lb.Dial == null ? "无" : lb.Dial))])]):_vm._e()],1):(lb.QuestionCategory === 5)?_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入论述","disabled":""},model:{value:(lb.StudentAnswerStr),callback:function ($$v) {_vm.$set(lb, "StudentAnswerStr", $$v)},expression:"lb.StudentAnswerStr"}}),(lb.parsingShow == false)?_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.parsClick(lb)}}},[_vm._v("查看答案及解析")]):_vm._e(),(lb.parsingShow == true)?_c('div',{staticClass:"js"},[_c('div',[_vm._v(" 【答案】: "+_vm._s(lb.AnswerStr == null ? "无" : lb.AnswerStr)+" ")]),_c('div',[_vm._v(" 【解析】: "),_c('span',{domProps:{"innerHTML":_vm._s(
                    lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                  )}})]),_c('div',[_vm._v("【点拨】: "+_vm._s(lb.Dial == null ? "无" : lb.Dial))])]):_vm._e()],1):_vm._e()],1)})],2)}),0)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }